import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IRoute} from 'app/blocks/model/route.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';

export interface ISchedule extends IBaseEntity {
    id?: number;
    scheduleType?: string;
    scheduleSubType?: string;
    startDate?: any;
    endDate?: any;
    interval?: number;
    wdaysOfWeek?: string;
    mlastWeekOfMonth?: boolean; // not used now
    mdaysOfMonth?: string;
    mweekOfMonth?: string; // by default "1,2,3,4"
    mdayOfWeek?: string;
    mdaysOfWeek?: string; // Sunday = 1... Saturday = 7
    mmonthsOfYear?: string; // numbers of months, example: "1,2,6"
    cronExpression?: string;
    lastSiteVisitDate?: any;
    skipSaturday?: boolean;
    skipSunday?: boolean;
    rescheduleSkippedDates?: boolean;
    route?: IRoute;
    deliveryPoints?: IDeliveryPoint[];
    scheduleName?: string;
    routeLevel?: boolean;
    enableTargetsForSchedule?: boolean;
    targetValue?: number;
}

export class Schedule extends BaseEntity implements ISchedule {
    public id?: number;
    public scheduleType?: string;
    public scheduleSubType?: string;
    public startDate?: any;
    public endDate?: any;
    public interval?: number;
    public wdaysOfWeek?: string;
    public mlastWeekOfMonth?: boolean;
    public mdaysOfMonth?: string;
    public mweekOfMonth?: string;
    public mdayOfWeek?: string;
    public mdaysOfWeek?: string;
    public mmonthsOfYear?: string;
    public cronExpression?: string;
    public lastSiteVisitDate?: any;
    public skipSaturday?: boolean;
    public skipSunday?: boolean;
    public rescheduleSkippedDates?: boolean;
    public route?: IRoute;
    public deliveryPoints?: IDeliveryPoint[];
    public scheduleName?: string;
    public routeLevel?: boolean;
    public enableTargetsForSchedule?: boolean;
    public targetValue?: number;

    constructor(schedule?) {
        super();
        if (schedule) {
            this.id = schedule.id;
            this.scheduleType = schedule.scheduleType;
            this.scheduleSubType = schedule.scheduleSubType;
            this.startDate = MposDate.newInstance(schedule.startDate);
            this.endDate = MposDate.newInstance(schedule.endDate);
            this.interval = schedule.interval;
            this.wdaysOfWeek = schedule.wdaysOfWeek;
            this.mlastWeekOfMonth = schedule.mlastWeekOfMonth;
            this.mdaysOfMonth = schedule.mdaysOfMonth;
            this.mweekOfMonth = schedule.mweekOfMonth;
            this.mdayOfWeek = schedule.mdayOfWeek;
            this.mdaysOfWeek = schedule.mdaysOfWeek;
            this.mmonthsOfYear = schedule.mmonthsOfYear;
            this.cronExpression = schedule.cronExpression;
            this.lastSiteVisitDate = schedule.lastSiteVisitDate;
            this.skipSaturday = schedule.skipSaturday;
            this.skipSunday = schedule.skipSunday;
            this.rescheduleSkippedDates = schedule.rescheduleSkippedDates;
            this.route = schedule.route;
            this.deliveryPoints = schedule.deliveryPoints;
            this.scheduleName = schedule.scheduleName;
            this.routeLevel = schedule.routeLevel;
            this.enableTargetsForSchedule = schedule.enableTargetsForSchedule;
            this.targetValue = schedule.targetValue;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Schedule {
        return new Schedule(this);
    }
}
